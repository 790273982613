import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import LandingPage from './LandingPage';

console.log('App component is rendering');

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {/* Add more routes here as needed */}
      </Routes>
    </div>
  );
}

export default App;